<template>
    <div>
        <v-card class="white px-2 px-md-4 py-2">
            <h3>Console response  : </h3>
            <pre>{{ displayMessages }}
            </pre>

        </v-card>
        
    </div>
</template>

<script>
import { methods } from '@/utils/constant.js';

export default {
    props: {
        messages: {
            type: Array,
            required: true,
        },
       
    },
    data(){
        return {
            loading:false,
            methods:methods
        }
    },
    computed:{
        displayMessages(){
            let display = ""
            for(let message of this.messages){
                display+=message.trim()
                display+="\n"
                
            }

            return display.trim()
        }
    },
    methods: {
       
    },
};
</script>

<style></style>
