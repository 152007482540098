import axios from "axios";
const mutations = {
    SET_USER_PROFILE(state, userProfile) {
        state.userProfile = userProfile
    },
    SET_SHOPING_CART_PROFILE(state, data) {
        state.userShopingCart = data
    },
    /**
     * @user
     */
    SET_USERS(state, data) {
        state.users = data;
    },
    SET_USER(state, data) {
        state.user = data;
    },
    /**
     * @workspace
     */
    SET_WORSPACES(state, data) {
        state.workspaces = data;
    },
    SET_GUEST_WORSPACES(state, data) {
        state.guestWorkspaces = data;
    },
    SET_WORSPACE(state, data) {
        state.workspace = data;
    },
    SET_WORSPACE_INVITATIONS(state, data) {
        state.workspaceInvitations = data;
    },
    /**
     * @project
     */
    SET_PROJECTS(state, data) {
        state.projects = data;
    },
    SET_PROJECT(state, data) {
        state.project = data;
    },
    /**
     * @model
     */
    SET_MODELS(state, data) {
        state.models = data;
    },
    SET_MODEL(state, data) {
        state.model = data;
    },
    /**
     * @attribute
     */
    SET_ATTRIBUTES(state, data) {
        state.attributes = data;
    },
    SET_ATTRIBUTE(state, data) {
        state.attribute = data;
    },

    /**
     * @template
     */
    SET_TEMPLATES(state, data) {
        state.templates = data;
    },
    SET_TEMPLATE(state, data) {
        state.template = data;
    },
    /**
     * @backendTest
     */
    SET_BACKEND_TESTS(state, data) {
        state.backendTests = data;
    },
    SET_BACKEND_TEST(state, data) {
        state.backendTest = data;
    },
    /**
     * @backendTestScenario
     */
    SET_BACKEND_TEST_SCENARIOS(state, data) {
        state.backendTestScenarios = data;
    },
    SET_BACKEND_TEST_SCENARIO(state, data) {
        state.backendTestScenario = data;
    },
    /**
     * @pricing
     */
    SET_PRICINGS(state, data) {
        state.pricings = data;
    },
    SET_PRICING(state, data) {
        state.pricing = data;
    },
    /**
     * @subscription
     */
    SET_SUBSCRIPTIONS(state, data) {
        state.subscriptions = data;
    },
    SET_ACTIVE_SUBSCRIPTION(state, data) {
        state.activesSubscription = data;
    },
    SET_SUBSCRIPTION(state, data) {
        state.subscription = data;
    },

}

const state = () => ({
    users: [],
    user: {},
    workspaces: [],
    guestWorkspaces: [],
    workspace: {},
    workspaceInvitations: [],
    projects: [],
    project: {},
    model: {},
    models: [],
    backendTest: {},
    backendTestScenario: {},
    backendTests: [],
    backendTestScenarios: [],
    template: {},
    templates: [],
    userProfile: {},
    userShopingCart: {},
    pricing: {},
    pricings: [],
    activesSubscription: null,
    subscription: {},
    subscriptions: [],


})

const getters = {
    getUser(state) {
        return state.user
    },
    user: state => state.user,
    users: state => state.users,
    workspace: state => state.workspace,
    workspaces: state => state.workspaces,
    guestWorkspaces: state => state.guestWorkspaces,
    workspaceInvitations: state => state.workspaceInvitations,
    project: state => state.project,
    projects: state => state.projects,
    model: state => state.model,
    models: state => state.models,
    backendTest: state => state.backendTest,
    backendTests: state => state.backendTests,
    backendTestScenario: state => state.backendTestScenario,
    backendTestScenarios: state => state.backendTestScenarios,
    pricing: state => state.pricing,
    pricings: state => state.pricings,
    activesSubscription: state => state.activesSubscription,
    subscription: state => state.subscription,
    subscriptions: state => state.subscriptions,
    userProfile: state => state.userProfile,
    userShopingCart: state => state.userShopingCart,
}



const actions = {
    // async onAuthStateChangedAction(state, { authUser, claims }) {
    //     if (!authUser) {
    //         // remove state
    //         state.commit('SET_USER', null)

    //         //redirect from here
    //         this.$router.push({
    //             path: '/auth/signin',
    //         })
    //     } else {
    //         const { uid, email } = authUser
    //         state.commit('SET_USER', {
    //             uid,
    //             email,
    //         })
    //     }
    // },

    /**
     * @project
     */
    getUserProfile({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/admin/profile`)
                .then(res => {
                    commit("SET_USER_PROFILE", res.data.user);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    /**
     * @shopingcart
     */
    getMyShopingCart({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/shopingcart/profile`)
                .then(res => {
                    commit("SET_SHOPING_CART_PROFILE", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    /**
     * @template
     */
    getTemplates({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/template`)
                .then(res => {
                    commit("SET_TEMPLATES", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getTemplate({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/template/${id}`)
                .then(res => {
                    commit("SET_TEMPLATE", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    /**
     * @user
     */
    getUsers({ commit }, schema) {
        return new Promise((resolve, reject) => {

            axios
                .get(`/admin?withoutMyAccount=${schema && schema.withoutMyAccount ? true : false }&workspace=${schema && schema.workspace ? schema.workspace : '' }`)
                .then(res => {
                    commit("SET_USERS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getUser({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/admin/${id}`)
                .then(res => {
                    commit("SET_USER", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    /**
     * @workspace
     */
    getWorkspaces({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/workspace?myWorkspace=true`)
                .then(res => {
                    commit("SET_WORSPACES", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getGuestWorkspaces({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/workspace?myWorkspace=false`)
                .then(res => {
                    commit("SET_GUEST_WORSPACES", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getWorkspace({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/workspace/${id}`)
                .then(res => {
                    commit("SET_WORSPACE", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getWorkspaceInvitations({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/workspace/invitation?id=${id || ''}`)
                .then(res => {
                    commit("SET_WORSPACE_INVITATIONS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    /**
     * @project
     */
    getProjects({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/project`)
                .then(res => {
                    commit("SET_PROJECTS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getProject({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/project/${id}`)
                .then(res => {
                    commit("SET_PROJECT", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    /**
     * @model
     */
    getModels({ commit }, { project, isActor = '' }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/project/${project}/model?isActor=${isActor}`)
                .then(res => {
                    commit("SET_MODELS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getModel({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/model/${id}`)
                .then(res => {
                    commit("SET_MODEL", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    /**
     * @backendTest
     */
    getBackendTests({ commit }, { project }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/backendtest?project=${project}`)
                .then(res => {
                    commit("SET_BACKEND_TESTS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getBackendTest({ commit }, { id, backendTestScenarioQuery }) {
        return new Promise((resolve, reject) => {


            axios
                .get(`/backendtest/${id}?scenarioName=${backendTestScenarioQuery.scenarioName}&scenarioRoute=${backendTestScenarioQuery.scenarioRoute}&scenarioGotExpectedStatus=${backendTestScenarioQuery.scenarioGotExpectedStatus}&scenarioMethod=${backendTestScenarioQuery.scenarioMethod}&scenarioActor=${backendTestScenarioQuery.scenarioActor}&scenarioExpectedStatues=${backendTestScenarioQuery.scenarioExpectedStatues}&scenarioStatus=${backendTestScenarioQuery.scenarioStatus}`)
                .then(res => {
                    commit("SET_BACKEND_TEST", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    /**
     * @backendTestScenario
     */
    getBackendTestScenarios({ commit }, { backendtest }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/backendtestscenario?backendtest=${backendtest}`)
                .then(res => {
                    commit("SET_BACKEND_TEST_SCENARIOS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getBackendTestScenario({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/backendtestscenario/${id}`)
                .then(res => {
                    commit("SET_BACKEND_TEST_SCENARIO", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    /**
     * @pricing
     */
    getPricings({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/pricing`)
                .then(res => {
                    commit("SET_PRICINGS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getPricing({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/pricing/${id}`)
                .then(res => {
                    commit("SET_PRICING", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    /**
     * @subscription
     */
    getSubscriptionList({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/subscription/my`)
                .then(res => {
                    commit("SET_SUBSCRIPTIONS", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


    getActiveSubscription({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/subscription/my?isActive=yes`)
                .then(res => {
                    commit("SET_ACTIVE_SUBSCRIPTION", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },


}


export default {
    state,
    actions,
    mutations,
    getters,
}