import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);



// dark: false,
const colorsLight = {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#00154c',
    dash: '#fafafa',
    'primary-darken-1': '#3700B3',
    secondary: '#6b6b6b',
    icon: '#d7d6d6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
}



export default new Vuetify({
    theme: {

        themes: {
            light: colorsLight,
        }
    }
});