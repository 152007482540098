/**
 * @Data
 */
export const data = {
    errors: '',
    loading: false,
    response: '',
    valid: true,

    passwordVisibility: {
        icon: 'mdi-eye',
        type: 'password',
    },
    confirmPasswordVisibility: {
        icon: 'mdi-eye',
        type: 'password',
    },
}

/**
 * @Function
 */
export const methods = {
    managePasswordVisibility(schema) {
        if (schema.type === 'password') {
            schema.icon = 'mdi-eye-off'
            schema.type = 'text'
        } else {
            schema.icon = 'mdi-eye'
            schema.type = 'password'
        }
    },
    getIdList(array) {
        const result = []
        array.forEach((item) => {
            result.push(item._id)
        })
        return result
    },
    getNameList(array, field = "name", unWantedList = []) {
        let result = [];
        array.map(item => {
            /**
             * Verification lang
             */
            if (item.fr && item.fr[field]) {
                result.push(item.fr[field]);
            } else if (item.en && item.en[field]) {
                result.push(item.en[field]);
            } else if (item && item[field]) {
                if (!unWantedList.includes(item[field])) {
                    result.push(item[field]);
                }
            }
        });
        return result;
    },
    getItemId(array, item, field = "name", value = "_id") {
        for (let i = 0; i < array.length; i++) {
            const element = array[i];

            if (element.fr && element.fr[field]) {
                if (element.fr[field] == item) return element[value];
            } else if (element.en && element.en[field]) {
                if (element.en[field] == item) return element[value];
            } else if (element && element[field]) {
                if (element[field] == item) {

                    if (value == "*") return element
                    return element[value]
                }
            }
        }
        return undefined;
    },
    isMongooseId(id) {
        if (id.match(/^[0-9a-fA-F]{24}$/)) {
            // it's an ObjectID   
            return true
        } else {
            // nope    
            return false
        }
    },

    seedStoreSelect(value, store) {
        let result = ''
        if (value) {
            if (typeof value == 'object') {
                result = this.getItemId(store, value._id, '_id', 'name')
            } else {
                if (this.isMongooseId(value)) {
                    result = this.getItemId(store, value, '_id', 'name')

                } else {
                    result = value
                }
                // alert(JSON.stringify(store))
                // console.log("value", value)
            }
        }

        return result
    },
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    },
    getStringFromArray(array) {
        if (Array.isArray(array)) {
            return array.join(',')
        }
    },
    limitTextCharacters(text, limit = 200, min = 0) {

        if (text.length < min) {
            // 
        }
        if (text.length > limit) return text.slice(0, limit - 3) + '...'
        return text
    },
    getFirstCharacter(text) {
        if (typeof text != 'string') {
            text = JSON.stringify(text)
        }

        text = text.trim()
        if (!text) return "M"

        return text[0].toUpperCase()
    },
    // date
    getDate(date) {
        date = new Date(date)
        return date.getDate()
    },
    getDay(date) {
        const days = [
            'Dimanche',
            'Lundi',
            'Mardi',
            'Mercredi',
            'Jeudi',
            'Vendredi',
            'Samedi',
        ]
        date = new Date(date)
        return days[date.getDay()]
    },
    getMonth(date) {
        const months = [
            'Janvier',
            'Fevrier',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Auout',
            'Septembre',
            'Octobre',
            'Novembre',
            'Decembre',
        ]
        date = new Date(date)
        return months[date.getMonth()]
    },
    getYear(date) {
        date = new Date(date)
        return date.getFullYear()
    },

    // product
    getShortProductModel(product) {
        const characteristic = product && Array.isArray(product.characteristics) && product.characteristics.length > 0 ? product.characteristics[0] : null

        if (!characteristic) return null
        if (!Array.isArray(characteristic.details) || !characteristic.details[0]) return null

        return {
            name: product.name,
            slug: product.slug,
            src: product.medias[0],
            price: characteristic.details[0].price,
            onDiscount: characteristic.details[0].onDiscount,
            promotionalPrice: characteristic.details[0].promotionalPrice,
            characteristics: product.characteristics,
            characteristic,
        }
    },
    productBody($name) {
        return $name
    },
    productSubject($name) {
        return $name
    },
    shareLink($path) {
        return `http://localhost:3000${$path}`
    },
    shareProductSubject($name) {
        return $name
    },

    /**
     * @dispatch error
     */
    dispatchError($store, message = 'Something went wrong') {
        // $store.dispatch('setSnackMessage', {
        //     message,
        //     snackbar: {
        //         bg: 'red',
        //         color: 'white',
        //         status: true,
        //     },
        // })
        $store.dispatch('setSnackMessage', {
            message,
            snackbar: {
                color: 'white',
                status: true,
                bg: 'green',

            },
        })
    },

    /**
     * @dispatch success
     */
    dispatchSuccess($store, message = 'Operation completed successfully') {
        $store.dispatch('setSnackMessage', {
            message,
            snackbar: {
                color: 'white',
                status: true,
                bg: 'green',

            },
        })
    },
    /**
     * @dispatch consoleComponent
     */
    dispatchConsoleComponenent($store, options) {

        const { messages } = options
        $store.dispatch('setConsoleComponenent', {
            messages,
            dialog: true,
        })
    },
    /**
     * @inArray
     */
    inArray(array, ...items) {
        for (let i = 0; i < items.length; i++) {
            for (let c = 0; c < array.length; c++) {
                if (items[i] === array[c]) return true
            }
        }
        return false
    },

    /**
     * @removeArrayElements
     */
    removeArrayElements(arr1, arr2) {
        return arr1.filter(element => !arr2.includes(element));
    },
    /**
     * Get drawer format from department schema
     */
    getDrawerFormatFromDpt(departments) {
        const menu = {
            name: 'Achète par département',
            subMenu: [],
        }
        departments.forEach((department) => {
            const categories = []
            department.categories.forEach((category) => {
                categories.push({
                    name: category.name,
                    link: `/category/${category.name}`,
                })
            })
            menu.subMenu.push({
                name: department.name,
                list: categories,
            })
        })

        return {
            menu,
            seeAll: false,
        }
    },
    formatNumber(number, minimumFractionDigits = 0) {
        if (!number || !Number(number)) return number

        // return number
        return (number).toLocaleString(
            undefined, // leave undefined to use the visitor's browser 
            // "en-US", // leave undefined to use the visitor's browser 
            // 'de-DE', // leave undefined to use the visitor's browser 
            { minimumFractionDigits }
        );
    },
    getAuthList(asJson = true) {
        const user = "User"
        const admin = "Admin"
        const customer = "Customer"
        const seller = "Seller"

        return asJson ? { user, admin, customer, seller } : [user, admin, customer, seller]
    },

    getTypeList(asJson = true) {
        const string = "String"
        const number = "Number"
        const boolean = "Boolean"
        const objectId = "mongoose.Schema.Types.ObjectId"
        const array = "Array"
            // const singleObjectArray = "SingleObjectArray"
        const objectArray = "ObjectArray"
        const date = "Date"
        const object = "Object"

        return asJson ? {
            string,
            number,
            boolean,
            objectId,
            array,
            // singleObjectArray,
            objectArray,
            object,
            date
        } : [string, number, boolean, objectId, array,
            // singleObjectArray,
            objectArray,
            object, date
        ]
    },

    getModelTypes(asJson = true) {
        const toggle = "toggle"
        const manage = "manage"
        const defaultType = "default"
        const usernamePassword = "usernamePassword"
        const emailPassword = "emailPassword"
        const emailOrUsernamePassword = "emailOrUsernamePassword"
        const simpleRequest = "simpleRequest"
        const simpleProduct = "simpleProduct"
        const defaultGetMethod = "defaultGetMethod"


        // const uniqueCode = "uniqueCode"
        return asJson ? { defaultType, usernamePassword, emailPassword, emailOrUsernamePassword, simpleRequest, simpleProduct, toggle, manage, defaultGetMethod } : [defaultType, usernamePassword, emailPassword, emailOrUsernamePassword, simpleRequest, simpleProduct, toggle, manage, defaultGetMethod]
    },

    getBackendTestType(asJson = true) {
        const blank = "blank"
        const full = "full"
        return asJson ? { blank, full } : [blank, full]
    },
    getProjectFeature(asJson = true) {
        const simpleEnventoryMangement = "Simple stock management"
        const simplePaymentSystem = "Simple Payment System"
        const eCommerceCartSystem = "E Commerce Cart System"
        const blank = "Blank"
        return asJson ? { simpleEnventoryMangement, simplePaymentSystem, eCommerceCartSystem, blank } : [simpleEnventoryMangement, simplePaymentSystem, eCommerceCartSystem, blank]
    },
    getMarchineServerAppProjectType(asJson = true) {
        const backendNodejs = "Backend Nodejs"
            // const frontendNuxt = "Frontend Nuxt"
        return asJson ? {
            backendNodejs,
            // frontendNuxt 
        } : [backendNodejs,
            // frontendNuxt
        ]
    },
    getOperatingSystem(asJson = true) {
        const ubuntu = "Ubuntu"
        const centOS = "CentOS"
        const debian = "Debian"
        const fedora = "Fedora"
        const redHatEnterpriseLinux = "Red Hat Enterprise Linux"
            // const openSUSE = "openSUSE"
            // const windowsServer = "Windows Server"
            // const windows10 = "Windows 10"
            // const windows8 = "Windows 8"
            // const windows7 = "Windows 7"
            // const windowsXP = "Windows XP"
            // const macOSMonterey = "macOS Monterey"
            // const macOSBigSur = "macOS Big Sur"
            // const macOSCatalina = "macOS Catalina"
            // const macOSMojave = "macOS Mojave"
            // const macOSHighSierra = "macOS High Sierra"
            // const macOSSierra = "macOS Sierra"

        return asJson ? {
            ubuntu,
            centOS,
            debian,
            fedora,
            redHatEnterpriseLinux,
            // openSUSE,
            // windowsServer,
            // windows10,
            // windows8,
            // windows7,
            // windowsXP,
            // macOSMonterey,
            // macOSBigSur,
            // macOSCatalina,
            // macOSMojave,
            // macOSHighSierra,
            // macOSSierra

        } : [ubuntu,
            centOS,
            debian,
            fedora,
            redHatEnterpriseLinux,
            // openSUSE,
            // windowsServer,
            // windows10,
            // windows8,
            // windows7,
            // windowsXP,
            // macOSMonterey,
            // macOSBigSur,
            // macOSCatalina,
            // macOSMojave,
            // macOSHighSierra,
            // macOSSierra
        ]
    },
    getBackendType(asJson = true) {
        const nodejs = "Node JS"
            // const blank = "Blank"
        return asJson ? { nodejs } : [nodejs]
    },
    getDatabaseType(asJson = true) {
        const mongoDb = "Mongo DB"
            // const blank = "Blank"
        return asJson ? { mongoDb } : [mongoDb]
    },
    getPackageManager(asJson = true) {
        const npm = "npm"
        const yarn = "yarn"
        return asJson ? { npm, yarn } : [npm, yarn]
    },
    getFrontendType(asJson = true) {
        const vuejs = "Vue JS"
            // const blank = "Blank"
        return asJson ? { vuejs } : [vuejs]
    },
    getFrontendCssFramework(asJson = true) {
        const vuetify = "Vuetify"
        return asJson ? { vuetify } : [vuetify]
    },

    clickURL(url) {
        var link = document.createElement("a");
        link.href = url;
        link.target = '_BLANK'
            // console.log(url)
        link.click();
    },
    getBaseControllerClassMethodTypeList(asJson = true) {
        const blank = "blank"
        const manage = "manage"
        const getListAlgo = "getListAlgo"
        const showAlgo = "showAlgo"
        return asJson ? {
            blank,
            manage,
            getListAlgo,
            showAlgo,
        } : [blank, manage, getListAlgo, showAlgo]
    },
    getClassMethodTypeList(asJson = true) {
        const blank = "blank"
            // const document = "document"
        const seedJson = "seedJson"
        const createMethod = "createMethod"
        const updateMethod = "updateMethod"
        const getListMethod = "getListMethod"
        const showMethod = "showMethod"
        const deleteMethod = "deleteMethod"
            // const feedBackTransaction = "feedBackTransaction"
            // const waxuHook = "waxuHook"
            // const fedapayHook = "fedapayHook"
            // const updateMultiple = "updateMultiple"
            // const updateMultipleIds = "updateMultipleIds"
            // const toggleActive = "toggleActive"

        return asJson ? {
            blank,
            createMethod,
            updateMethod,
            getListMethod,
            showMethod,
            deleteMethod,
            // document,
            seedJson,
        } : [blank, createMethod, updateMethod, deleteMethod,
            // document, 
            seedJson, getListMethod, showMethod
        ]
    },
    getRequestMethodTypeList(asJson = true) {
        const get = "get"
        const post = "post"
        const put = "put"
        return asJson ? {get, post, put, delete: "delete" } : [get, post, put, "delete"]
    },
    getAuthMethodTypeList(asJson = true) {
        const authorize = "authorize"
        const rolevalidation = "rolevalidation"
        const tokenvalidation = "tokenvalidation"
        return asJson ? { authorize, rolevalidation, tokenvalidation } : [authorize, rolevalidation, tokenvalidation]
    },
    /**
     * @dispatch delete dialog
     */
    dispatchDeleteDialog($store, schema) {
        $store.dispatch('setDeleteDialog', schema);
    },

    getWorkspaceMemberRoles(asJson = true) {
        const owner = "Owner"
        const developer = "Developer"
        const maintainer = "Maintainer"
        const reporter = "Reporter"
        const guest = "Guest"

        return asJson ? { owner, guest, reporter, developer, maintainer } : [owner, guest, reporter, developer, maintainer]
    },
    getWorkspaceMemberStatus(asJson = true) {
        const activated = "Activated"
        const disabled = "Disabled"
        const waiting = "Waiting"
        const reject = "Reject"
        const cancel = "Cancel"
        const expired = "Expired"

        return asJson ? { activated, disabled, waiting, reject, cancel, expired } : [activated, disabled, waiting, reject, cancel, expired]
    },

    playNotificationSound(type = 'default') {
        if (!type) return
        const audio = new Audio(require('@/assets/audio/notification.wav')); // Path to your audio file
        audio.play();
    },
    obfuscateEmail(email) {
        // Split the email into local part and domain part
        const [localPart, domainPart] = email.split('@');

        // Obfuscate the local part
        let obfuscatedLocal;
        if (localPart.length <= 2) {
            obfuscatedLocal = localPart[0] + '*'.repeat(localPart.length - 1);
        } else {
            obfuscatedLocal = localPart.slice(0, 2) + '*'.repeat(localPart.length - 2);
        }

        // Split the domain part into domain name and TLD
        const domainParts = domainPart.split('.');
        const domainName = domainParts[0];
        const tld = domainParts.slice(1).join('.'); // Handle cases with multiple dots in the domain

        // Obfuscate the domain name
        let obfuscatedDomain;
        if (domainName.length <= 1) {
            obfuscatedDomain = '*';
        } else {
            obfuscatedDomain = domainName[0] + '*'.repeat(domainName.length - 1);
        }

        // Combine the obfuscated parts and add the TLD back
        const obfuscatedEmail = `${obfuscatedLocal}@${obfuscatedDomain}.${tld}`;

        return obfuscatedEmail;
    }
}

/**
 * @Rules
 */
export const rules = {
    required: (v) =>
        !!v ||
        `
  This field is required`,
    email: (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    password: (v) =>
        (v && v.length >= 8) ||
        'The password must contain at least 8 characters',


    max(number, message) {
        if (!message) message = `This field must be less than or equal to${number}`
        return v => (v && v.length <= number) || message
    },
    min(number, message) {
        if (!message) message = `This field must be greater than or equal to ${number}`
        return v => (v && v.length >= number) || message
    },
    diff(array, message = "This name is not available") {
        for (let item of array) {
            if (typeof item !== "string") {
                item = JSON.stringify(item)

                // alert(item)
            }
        }
        return v => (v && !array.includes(v)) || `${message}`
    },
}

/**
 *@specialCharacteristics
 */
export const specialCharacteristics = ['Interval']