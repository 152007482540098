const mutations = {
    /**
     * @snackbar
     */
    SET_SNACKBAR(state, data) {
        state.snackbar = data;
    },
    SET_SNACK_MESSAGE(state, data) {
        state.snackMessage = data;
    },

    /**
     * @deleteDialog
     */
    SET_DELETE_DIALOG(state, data) {
        state.deleteDialog = data;
    },
    /**
     * @consoleComponenent
     */
    SET_CONSOLE_COMPONENT(state, data) {
        state.consoleComponenent = data;
    },
}

const state = () => ({

    snackbar: {
        status: false,
        color: 'red',
        bg: 'black',
    },
    snackMessage: '',
    deleteDialog: {
        dialog: false,
        callback: null,
        data: {},
        what: '',
        description: '',
        successHandler: null,
    },
    consoleComponenent: {
        dialog: false,
        messages: []
    },

})

const getters = {
    snackbar: (state) => state.snackbar,
    snackMessage: (state) => state.snackMessage,
    deleteDialog: (state) => state.deleteDialog,
    consoleComponenent: (state) => state.consoleComponenent,
}


const actions = {

    /**
     * @snackbar
     */
    setSnackMessage({ commit }, schema) {
        return new Promise(() => {
            commit('SET_SNACKBAR', schema.snackbar);
            commit('SET_SNACK_MESSAGE', schema.message);
        });
    },

    /**
     * @deleteDialog
     */
    setDeleteDialog({ commit }, schema) {
        return new Promise(() => {
            commit('SET_DELETE_DIALOG', schema);
        });
    },
    /**
     * @consoleComponenent
     */
    setConsoleComponenent({ commit }, schema) {
        return new Promise(() => {
            commit('SET_CONSOLE_COMPONENT', schema);
        });
    },

}


export default {
    state,
    actions,
    mutations,
    getters,
}