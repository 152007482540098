/* eslint-disable */

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "./router/index";
import store from "./store";

import VueSocketIO from 'vue-socket.io';
import io from 'socket.io-client';





const socketInstance = io(process.env.SOCKET_IO_URL, {
    extraHeaders: {
        "admin-token": localStorage.getItem("admin-token")
    }
});


// Initialize the plugin with custom headers
Vue.use(new VueSocketIO({
    debug: true,
    connection: socketInstance,
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
}));

// Vue.use(VueSocketIO, socketInstance)

import "./axios/index";

Vue.config.productionTip = false

/**
 * String Prototype custome property
 */
if (!String.prototype.hasOwnProperty('capitalize')) {
    Object.defineProperty(String.prototype, 'capitalize', {
        value: function() {
            return this.charAt(0).toUpperCase() + this.slice(1);
        },
        enumerable: false
    });
}

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')