import Vue from "vue";
import Router from "vue-router";



Vue.use(Router);

const routes = [{
        path: "/",
        name: "App",
        component: () =>
            import ("@/layouts/Dashboard"),
        redirect: "/home",
        beforeEnter(to, from, next) {
            const admin = localStorage.getItem("admin-token");

            if (admin) {
                next();
            } else {
                next("/auth");
            }
        },
        meta: {
            title: "Orang Code ",
            metaTags: [{
                    name: "description",
                    content: "OrangCode Nodejs backend development platforme",
                },
                {
                    property: "og:description",
                    content: "OrangCode Nodejs backend development platforme",
                },
            ],
        },
        children: [{
                path: "/home",
                name: "Home",
                component: () =>
                    import ("@/views/User/Home"),
            },
            {
                path: "/project/:id",
                name: "Project",
                component: () =>
                    import ("@/views/User/Project"),
            },
            {
                path: "/subscription",
                name: "Subscription",
                component: () =>
                    import ("@/views/User/Subscription"),
            },
            {
                path: "/shopingcart",
                name: "ShopingCart",
                component: () =>
                    import ("@/views/User/ShopingCart"),
            },
            {
                path: "/pricing",
                name: "Pricings",
                component: () =>
                    import ("@/views/User/Pricing"),
            },
            {
                path: "/pricing/:id",
                name: "Pricing",
                component: () =>
                    import ("@/views/User/ShowPricing"),
            },
            {
                path: "/checkoutshopingcart",
                name: "Checkout",
                component: () =>
                    import ("@/views/User/Checkout"),
            },
            {
                path: "/active-subscription",
                name: "Checkout",
                component: () =>
                    import ("@/views/User/ActiveSubscription"),
            },
            {
                path: "/subscription-active-successfully",
                name: "Checkout",
                component: () =>
                    import ("@/views/User/ActiveSubscriptionSuccessfully"),
            },
            {
                path: "/active-subscription",
                name: "Checkout",
                component: () =>
                    import ("@/views/User/ActiveSubscription"),
            },
            {
                path: "/subscription-failed",
                name: "Checkout",
                component: () =>
                    import ("@/views/User/SubscriptionFailed"),
            },
        ]

    },
    {
        path: "/auth",
        name: "AuthApp",
        component: () =>
            import ("@/layouts/Auth"),
        beforeEnter(to, from, next) {
            const admin = localStorage.getItem("admin-token");

            if (admin) {
                next("/home");
            } else {
                next();
            }
        },
        redirect: "/auth/login",
        meta: {
            title: "Orang Code ",
            metaTags: [{
                    name: "description",
                    content: "Orang Code Signin page",
                },
                {
                    property: "og:description",
                    content: "Orang Code Signin page",
                },
            ],
        },
        children: [{
                path: "/auth/login",
                name: "Login",
                component: () =>
                    import ("@/views/User/Login"),
            },
            {
                path: "/auth/signup",
                name: "SignUp",
                component: () =>
                    import ("@/views/User/SignUp"),
            },
            {
                path: "/auth/forget-password",
                name: "ForgetPassword",
                component: () =>
                    import ("@/views/User/ForgetPassword"),
            },
            {
                path: "/auth/verify-restauration-code",
                name: "VerifyRestaurationCode",
                component: () =>
                    import ("@/views/User/VerifyRestaurationCode"),
            },
            {
                path: "/auth/verify-email",
                name: "VerifyEmail",
                component: () =>
                    import ("@/views/User/VerifyEmail"),
            },
            {
                path: "/auth/new-password",
                name: "NewPassword",
                component: () =>
                    import ("@/views/User/NewPassword"),
            },
            {
                path: "/auth/double-authentification-verification",
                name: "VerifyDoubleAuthentificationCode",
                component: () =>
                    import ("@/views/User/VerifyDoubleAuthentificationCode"),
            },
        ]
    },
    {
        path: "/about",
        name: "AboutApp",
        component: () =>
            import ("@/layouts/Default"),
        redirect: "/about",
        children: [{
                path: "/about",
                name: "About",
                component: () =>
                    import ("@/views/Guest/About"),
            },

        ]

    },
    {
        path: "/policy",
        name: "PolicyApp",
        component: () =>
            import ("@/layouts/Default"),
        redirect: "/policy",
        children: [{
                path: "/policy",
                name: "Policy",
                component: () =>
                    import ("@/views/Guest/Policy"),
            },

        ]

    },

    // {
    //     path: "/login",
    //     name: "Login",
    //     component: Login,
    //     beforeEnter(to, from, next) {
    //         const student = localStorage.getItem("e-student");
    //         const admin = localStorage.getItem("e-admin");
    //         const professor = localStorage.getItem("professor");

    //         // student
    //         if (student) {
    //             next("/student/profil");
    //         }
    //         if (admin) {
    //             if (professor) next("/professor/profil");
    //             next("/admin/profil");
    //         }
    //         // if (professor) {
    //         //     next('/professor/profil')
    //         // }
    //         else {
    //             next();
    //         }
    //     },
    // },



    {
        path: "*",
        redirect: "/",
    },
];

//

const router = new Router({
    mode: "history",
    routes,
    base: '/',
});

// ...

// This callback runs before every route change, including on page load.

router.beforeEach((to, from, next) => {


    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(
        document.querySelectorAll("[data-vue-router-controlled]")
    ).map((el) => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map((tagDef) => {
            const tag = document.createElement("meta");

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute("data-vue-router-controlled", "");

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach((tag) => document.head.appendChild(tag));

    next();
});

// ...

export default router;